<template>
  <div>
    <b-col class="bulk-update-title">
      <h5>Bulk Update Unit Location</h5>
      <a href="#" class="nav-link-aside" @click="openOtherAside()">Bulk Update {{ otherBulkAsideLabel }}</a>
    </b-col>

    <div class="mr-3">
      <label class="col-head">Location:</label>
      <div>
        <v-select
          v-model="location"
          label="description"
          :class="$v.location.$error && !$v.location.required ? 'is-invalid' : ''"
          :options="activeUnitLocations"
          :clearable="false"
          select-on-tab
        >
          <template #selected-option="selectedLocation">
            <span class="text-uppercase">{{ selectedLocation.description }}</span>
          </template>
        </v-select>
      </div>
      <b-form-group label="Comments: " label-class="col-head" label-for="comments" class="py-2">
        <div class="d-flex">
          <b-textarea id="comments" v-model="comments" class="overflow-auto rounded" size="sm" rows="1" max-rows="4" />
        </div>
      </b-form-group>
      <div>
        <b-button variant="primary mt-2" @click="confirmSave">
          <div v-show="savingLocation">
            <b-spinner small></b-spinner>
            Saving...
          </div>
          <div v-show="!savingLocation">Save</div>
        </b-button>
        <b-button variant="secondary mt-2 ml-2" @click="cancel">
          <div>Cancel</div>
        </b-button>
      </div>
    </div>
    <warning-modal
      id="BulkUpdateWarning"
      ref="BulkUpdateWarning"
      title="Bulk Updates"
      :warning-text="confirmationMessage"
      continue-btn-text="Yes"
      cancel-btn-text="Cancel"
    ></warning-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import BulkUpdateService from '@/shared/services/BulkUpdateService';
import SuccessService from '@/shared/services/SuccessService';
import ErrorService from '@/shared/services/ErrorService';
import { required } from 'vuelidate/lib/validators';

// Components
import WarningModal from '@/shared/components/WarningModal';

// Vuex
import { LookupGetters } from '@/shared/store/lookup/types';
import { mapGetters } from 'vuex';
import { UserGetters } from '@/shared/store/user/types';

export default {
  name: 'BulkUpdateLocation',
  components: {
    'warning-modal': WarningModal,
    vSelect
  },
  props: {
    selectedUnits: {
      type: Array,
      required: true
    },
    otherBulkAsideLabel: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      NA: 'N/A',
      savingLocation: false,
      comments: null,
      location: null
    };
  },
  validations: {
    location: {
      required
    }
  },
  computed: {
    ...mapGetters({
      locationsList: LookupGetters.GET_LOCATIONS_LIST
    }),
    activeUnitLocations() {
      return this.locationsList.filter(l => !l.inactive && l.description.indexOf('000') <= -1);
    },
    totalUnits() {
      return this.selectedUnits.length;
    },
    selectedUnitIds() {
      return this.selectedUnits.map(u => u.unitId);
    },
    confirmationMessage: function () {
      return `Are you sure you want to bulk update the ${this.totalUnits} units selected?`;
    },
    myBranches() {
      return this.$store.getters[UserGetters.GET_USER_PROFILE].employeeBranches;
    }
  },
  async created() {
    if (this.activeUnitLocations.length === 1) {
      this.location = this.activeUnitLocations[0];
    } else if (this.myBranches.length === 1) {
      var myBranchId = this.myBranches[0].branchId;
      var myLocations = this.activeUnitLocations.filter(l => l.branchId.indexOf(myBranchId) >= 0);
      if (myLocations.length === 1) {
        this.location = myLocations[0];
      }
    }
  },
  methods: {
    confirmSave: function () {
      this.$v.$touch();
      if (this.$v.$anyError) {
        ErrorService.createErrorToast(this, 'Error bulk updating unit location. See indicated fields below.');
        return;
      }
      this.$refs.BulkUpdateWarning.show(this, this.saveLocation.bind(this));
    },
    saveLocation: async function () {
      const bulkUpdate = {
        unitIds: this.selectedUnitIds,
        locationId: this.location.id,
        comments: this.comments
      };
      try {
        this.savingLocation = true;
        await BulkUpdateService.postBulkUpdateLocation(bulkUpdate);
        this.resetPage();
        SuccessService.createSuccessToast(this.$root, 'Unit Location Updated on Bulk Units.');
        this.$emit('updateLocations', this.location);
      } catch (error) {
        ErrorService.createErrorToast(this, 'Failed to bulk update unit location.');
      } finally {
        this.savingLocation = false;
        this.location = null;
        this.comments = null;
      }
    },
    resetPage: function () {
      this.$v.$reset();
    },
    cancel() {
      this.$emit('close');
    },
    openOtherAside() {
      this.$emit('openOther');
    },
    hasChanges() {
      return this.location !== null || this.comments !== null;
    }
  }
};
</script>
